import { lazy } from 'react';

const PagesRoutes = [
  // PAGES
  {
    path: '/pages/blank-page',
    component: lazy(() => import('../../view/pages/blank')),
    layout: 'VerticalLayout',
  },
  {
    path: '/pages/error-404',
    component: lazy(() => import('../../view/pages/errors/404')),
    layout: 'FullLayout',
  },
  // ** CUSTOM PAGES __ OEKFB **
  // MARK: ADMIN PAGES
  {
    path: "/admin/",
    component: lazy(() => import("../../view/oekfb/admin")),
    layout: "HorizontalLayout",
  },
  {
    path: "/admin/user",
    component: lazy(() => import("../../view/oekfb/admin/subviews/user")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/team/users",
    component: lazy(() => import("../../view/oekfb/admin/subviews/teams/teamUsers")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/team/pending",
    component: lazy(() => import("../../view/oekfb/admin/subviews/teams/pendingIndex")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/user_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/user/userDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/team",
    component: lazy(() => import("../../view/oekfb/admin/subviews/teams")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/team_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/teams/teamDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/players",
    component: lazy(() => import("../../view/oekfb/admin/subviews/teams/playerIndex")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/players_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/teams/playerDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/referee",
    component: lazy(() => import("../../view/oekfb/admin/subviews/referees")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/referee_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/referees/refereeDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/league",
    component: lazy(() => import("../../view/oekfb/admin/subviews/leagues")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/league_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/leagues/leagueDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/season_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/leagues/seasonDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/registrations",
    component: lazy(() => import("../../view/oekfb/admin/subviews/registrations")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/stadiums",
    component: lazy(() => import("../../view/oekfb/admin/subviews/stadium")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/stadium_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/stadium/stadiumDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/registration_detail/:id",
    component: lazy(() => import("../../view/oekfb/admin/subviews/registrations/registrationsDetail")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/chat",
    component: lazy(() => import("../../view/oekfb/admin/subviews/chat/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/rechnung_erstellung",
    component: lazy(() => import("../../view//pages/invoice")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/rechnungen",
    component: lazy(() => import("../../view//pages/invoice/RechnungenIndex")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/transfers",
    component: lazy(() => import("../../view/oekfb/admin/subviews/transfers/TransferIndex")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/transfer_settings",
    component: lazy(() => import("../../view/oekfb/admin/subviews/transfers/TransferSettings")),
    layout: "VerticalLayout",
  },

];

export default PagesRoutes;
