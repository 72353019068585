import React from 'react';
import { Modal, Form, Input, Select, Checkbox, Button } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import ApiService from '../../../../../network/ApiService';

const { Option } = Select;

const ManualRegistrationModal = ({ visible, onCancel }) => {
    const [form] = Form.useForm();
    const apiService = new ApiService();

    const generatePassword = (length = 6) => {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    };

    const initial_password = generatePassword();

    const handleCreate = async () => {
        try {
            const values = await form.validateFields();
            const payload = { ...values, initial_password };
            await apiService.post('registrations/register', payload);
            alert('Registration submitted!');
            form.resetFields();
            onCancel(); // Dismiss modal after successful submission
        } catch (errorInfo) {
            alert('Registration failed: ' + errorInfo.message);
        }
    };

    return (
        <Modal
            title={`Manual Registration - ${initial_password}`}
            width={600}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleCreate}>
                    Submit
                </Button>,
            ]}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
            <Form form={form} layout="vertical" name="manualRegistrationForm">
                <div style={{ marginBottom: 24 }}>
                    <h3>Contact Information</h3>
                    <Form.Item
                        label="Primary Contact First Name"
                        name={['primaryContact', 'first']}
                        rules={[{ required: true, message: 'Please input the primary contact first name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Primary Contact Last Name"
                        name={['primaryContact', 'last']}
                        rules={[{ required: true, message: 'Please input the primary contact last name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Primary Contact Phone"
                        name={['primaryContact', 'phone']}
                        rules={[{ required: true, message: 'Please input the primary contact phone!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Primary Contact Email"
                        name={['primaryContact', 'email']}
                        rules={[{ required: true, message: 'Please input the primary contact email!' }]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item
                        label="Secondary Contact First Name"
                        name={['secondaryContact', 'first']}
                        rules={[{ required: true, message: 'Please input the secondary contact first name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Secondary Contact Last Name"
                        name={['secondaryContact', 'last']}
                        rules={[{ required: true, message: 'Please input the secondary contact last name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Secondary Contact Phone"
                        name={['secondaryContact', 'phone']}
                        rules={[{ required: true, message: 'Please input the secondary contact phone!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Secondary Contact Email"
                        name={['secondaryContact', 'email']}
                        rules={[{ required: true, message: 'Please input the secondary contact email!' }]}
                    >
                        <Input type="email" />
                    </Form.Item>
                </div>

                <div style={{ marginBottom: 24 }}>
                    <h3>Registration Details</h3>
                    <Form.Item
                        label="Team Name"
                        name="teamName"
                        rules={[{ required: true, message: 'Please input the team name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Verein Name"
                        name="verein"
                    >
                        <Input placeholder="Optional" />
                    </Form.Item>
                    <Form.Item
                        label="Bundesland"
                        name="bundesland"
                        rules={[{ required: true, message: 'Please select the Bundesland!' }]}
                    >
                        <Select placeholder="Select a Bundesland">
                            <Option value="wien">Wien</Option>
                            <Option value="niederoesterreich">Niederösterreich</Option>
                            <Option value="oberoesterreich">Oberösterreich</Option>
                            <Option value="steiermark">Steiermark</Option>
                            <Option value="kaernten">Kärnten</Option>
                            <Option value="salzburg">Salzburg</Option>
                            <Option value="tirol">Tirol</Option>
                            <Option value="vorarlberg">Vorarlberg</Option>
                            <Option value="burgenland">Burgenland</Option>
                            <Option value="ausgetreten">Ausgetreten</Option>
                            <Option value="auszuwerten">Auszuwerten</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Club Type"
                        name="type"
                        rules={[{ required: true, message: 'Please select the club type!' }]}
                    >
                        <Select placeholder="Select a club type">
                            <Option value="privat">Privat</Option>
                            <Option value="professional">Professional</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="acceptedAGB"
                        valuePropName="checked"
                        rules={[{ required: true, message: 'You must accept the AGB!' }]}
                    >
                        <Checkbox>I accept the AGB.</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="Refer Code"
                        name="referCode"
                        // No validation rules here, so it remains optional
                    >
                        <Input />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default ManualRegistrationModal;
