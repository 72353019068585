import React, { useState } from 'react';
import { Modal, Form, Input, Button, Alert } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import ApiService from "../../../../../network/ApiService";

const apiService = new ApiService();

const AddUserModal = ({ visible, onCancel }) => {
    const [form] = Form.useForm();
    const [successMessage, setSuccessMessage] = useState(null);

    const onCreate = async () => {
        try {
            const formData = await form.validateFields();
            const { id, firstName, lastName, email, password } = formData;

            const body = {
                id, // Include id from form input
                firstName,
                lastName,
                email,
                password,
                type: 'admin'
            };

            // Make API call
            const response = await apiService.post('/users/', body);

            // Handle successful response
            console.log('User created:', response);

            // Display success message
            setSuccessMessage('User successfully created.');

            // Optionally, reset form fields
            form.resetFields();

            // Close modal or handle success feedback
            // onCancel(); // Commented out to show success message without closing modal immediately
        } catch (error) {
            console.error('Failed to add user:', error);
            // Handle error, e.g., show error message
        }
    };

    const handleCloseModal = () => {
        onCancel();
        setSuccessMessage(null); // Clear success message when closing modal
    };

    return (
        <Modal
            title="Neuer Admin User"
            width={416}
            visible={visible}
            onCancel={handleCloseModal}
            footer={[
                <Button key="cancel" onClick={handleCloseModal}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" onClick={onCreate}>
                    Speichern
                </Button>,
            ]}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
            {successMessage && (
                <Alert
                    message={successMessage}
                    type="success"
                    showIcon
                    closable
                    onClose={() => setSuccessMessage(null)}
                    style={{ marginBottom: 16 }}
                />
            )}

            <Form
                form={form}
                layout="vertical"
                name="addUserForm"
                initialValues={{ remember: true }}
            >
                <Form.Item
                    label="ID"
                    name="id"
                    rules={[{ required: true, message: 'Please input user ID!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Bitte geben Sie eine E-Mail-Adresse ein!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Vorname"
                    name="firstName"
                    rules={[{ required: true, message: 'Bitte geben Sie den Vornamen ein!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nachname"
                    name="lastName"
                    rules={[{ required: true, message: 'Bitte geben Sie den Nachnamen ein!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Passwort"
                    name="password"
                    rules={[{ required: true, message: 'Bitte geben Sie ein Passwort ein!' }]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddUserModal;
