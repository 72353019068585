import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, message, Input, InputNumber, Button, Menu, Dropdown, Space } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { User } from 'react-iconly';
import ApiService from "../../../../../network/ApiService";

export default function UserDetail() {
    const [user, setUser] = useState(null);
    const [originalUser, setOriginalUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [navlink, setNavlink] = useState("");
    const { id } = useParams();
    const history = useHistory();
    const apiService = new ApiService();

    useEffect(() => {
        const fetchUserDetail = async () => {
            if (!id) {
                setLoading(false);
                return;
            }

            try {
                const response = await apiService.get(`users/${id}`);
                if (response) {  // Directly check if response is available
                    // No need to parse JSON as it's already parsed
                    setUser(response);
                    setOriginalUser(response);
                    setNavlink(response.type === "admin" ? "/admin/user" : "/admin/team/users");
                } else {
                    message.error('User data not available');
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                message.error('Failed to fetch user details');
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetail();
    }, [id]);



    const fetchUpdatedUserData = async () => {
        const apiService = new ApiService();
        try {
            const data = await apiService.get(`users/internal/${user.id}`);
            if (data) {
                console.log('Fetched updated user data:', data);
                setUser(data);
                sessionStorage.setItem("userDetail", JSON.stringify(data));
            }
        } catch (error) {
            message.error('Failed to fetch updated user data');
            console.error('Fetch updated user data error:', error);
        }
    };

    const hasUserChanged = () => JSON.stringify(user) !== JSON.stringify(originalUser);

    const handleUpdateUser = async () => {
        if (hasUserChanged()) {
            const apiService = new ApiService();
            const userBody = {
                password_hash: user.password_hash,
                email: user.email,
                user_id: user.user_id,
                first_name: user.first_name,
                userID: user.id, // Mapping 'id' to 'userID'
                last_name: user.last_name,
                type: user.type
            };
            try {
                console.log('Updating user with ID:', user.id);
                console.log('Request Payload:', userBody);
                await apiService.patch(`users/${user.id}`, userBody);
                message.success('User updated successfully');
                await fetchUpdatedUserData();
            } catch (error) {
                message.error('Failed to update user');
                console.error('Update user error:', error);
            }
        } else {
            message.info('No changes to update');
        }
    };

    const handleDeleteUser = async () => {
        const apiService = new ApiService();
        try {
            await apiService.delete(`users/${user.id}`);
            message.success('User deleted successfully');
            history.push('/admin/users');
        } catch (error) {
            message.error('Failed to delete user');
            console.error('Delete user error:', error);
        }
    };

    const handleInputChange = (field, value) => {
        setUser(prevUser => ({ ...prevUser, [field]: value }));
    };

    const handleMenuClick = (e) => {
        handleInputChange('type', e.key);
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="admin" icon={<User set="curved" className="remix-icon" size={16} />}>
                Admin
            </Menu.Item>
            <Menu.Item key="referee" icon={<User set="curved" className="remix-icon" size={16} />}>
                Referee
            </Menu.Item>
            <Menu.Item key="team" icon={<User set="curved" className="remix-icon" size={16} />}>
                Team
            </Menu.Item>
        </Menu>
    );

    return (
        <div style={{ padding: 24 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <a href="/admin/">Administration</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href={navlink}>Benutzer</a> {/* Corrected href */}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>User Detail</Breadcrumb.Item>
                </Breadcrumb>
                <Space>
                    <Button
                        type="primary"
                        onClick={handleUpdateUser}
                        disabled={!hasUserChanged()}
                    >
                        Update
                    </Button>
                    <Button
                        type="danger"
                        onClick={handleDeleteUser}
                    >
                        Delete
                    </Button>
                </Space>
            </div>

            {user ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: 16 }}>
                    <Card title="ID">
                        <InputNumber
                            value={user.id}
                            onChange={(value) => handleInputChange('id', value)}
                            disabled
                            style={{ width: '100%' }}
                        />
                    </Card>
                    <Card title="Vorname">
                        <Input
                            value={user.first_name}
                            onChange={(e) => handleInputChange('first_name', e.target.value)}
                        />
                    </Card>
                    <Card title="Familiename">
                        <Input
                            value={user.last_name}
                            onChange={(e) => handleInputChange('last_name', e.target.value)}
                        />
                    </Card>
                    <Card title="Email">
                        <Input
                            value={user.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                    </Card>
                    <Card title="Type">
                        <Dropdown overlay={menu}>
                            <Button>
                                {user.type} <RiArrowDropDownLine className="remix-icon" size={24} />
                            </Button>
                        </Dropdown>
                    </Card>
                </div>
            ) : (
                <p>User data not available</p>
            )}
        </div>
    );
}
