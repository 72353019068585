import React, { useState } from 'react';
import { Modal, Form, Input, InputNumber, DatePicker, Select, Button } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import ApiService from "../../../../../network/ApiService";
import FirebaseImageUpload from "../../../../../network/Firebase/storage/FirebaseImageUpload";
const { Option } = Select;

// Function to generate a 7-digit random number
const generateRandomNumber = () => {
    return Math.floor(1000000 + Math.random() * 9000000).toString();
};

// List of all countries for the Nationality dropdown
const countries = [
    "Afghanistan", "Albanien", "Algerien", "Andorra", "Angola", "Antigua und Barbuda", "Argentinien",
    "Armenien", "Australien", "Österreich", "Aserbaidschan", "Bahamas", "Bahrain", "Bangladesch", "Barbados",
    "Weißrussland", "Belgien", "Belize", "Benin", "Bhutan", "Bolivien", "Bosnien und Herzegowina", "Botswana",
    "Brasilien", "Brunei", "Bulgarien", "Burkina Faso", "Burundi", "Kap Verde", "Kambodscha", "Kamerun",
    "Kanada", "Zentralafrikanische Republik", "Tschad", "Chile", "China", "Kolumbien", "Komoren", "Kongo",
    "Costa Rica", "Kroatien", "Kuba", "Zypern", "Tschechische Republik", "Dänemark", "Dschibuti", "Dominica",
    "Dominikanische Republik", "Ecuador", "Ägypten", "El Salvador", "Äquatorialguinea", "Eritrea", "Estland",
    "Eswatini", "Äthiopien", "Fidschi", "Finnland", "Frankreich", "Gabun", "Gambia", "Georgien", "Deutschland",
    "Ghana", "Griechenland", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
    "Ungarn", "Island", "Indien", "Indonesien", "Iran", "Irak", "Irland", "Israel", "Italien", "Jamaika",
    "Japan", "Jordanien", "Kasachstan", "Kenia", "Kiribati", "Kuwait", "Kirgistan", "Laos", "Lettland",
    "Libanon", "Lesotho", "Liberia", "Libyen", "Liechtenstein", "Litauen", "Luxemburg", "Madagaskar",
    "Malawi", "Malaysia", "Maldiven", "Mali", "Malta", "Marshallinseln", "Mauretanien", "Mauritius",
    "Mexiko", "Mikronesien", "Moldau", "Monaco", "Mongolei", "Montenegro", "Marokko", "Mosambik",
    "Myanmar", "Namibia", "Nauru", "Nepal", "Niederlande", "Neuseeland", "Nicaragua", "Niger",
    "Nigeria", "Nordkorea", "Nordmazedonien", "Norwegen", "Oman", "Pakistan", "Palau", "Palästina",
    "Panama", "Papua-Neuguinea", "Paraguay", "Peru", "Philippinen", "Polen", "Portugal", "Katar",
    "Rumänien", "Russland", "Ruanda", "St. Kitts und Nevis", "St. Lucia", "St. Vincent und die Grenadinen",
    "Samoa", "San Marino", "Sao Tome und Principe", "Saudi-Arabien", "Senegal", "Serbien", "Seychellen",
    "Sierra Leone", "Singapur", "Slowakei", "Slowenien", "Salomonen", "Somalia", "Südafrika",
    "Südkorea", "Südsudan", "Spanien", "Sri Lanka", "Sudan", "Surinam", "Schweden", "Schweiz",
    "Syrien", "Taiwan", "Tadschikistan", "Tansania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad und Tobago",
    "Tunesien", "Türkei", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "Vereinigte Arabische Emirate",
    "Vereinigtes Königreich", "Vereinigte Staaten", "Uruguay", "Usbekistan", "Vanuatu", "Vatikanstadt", "Venezuela",
    "Vietnam", "Jemen", "Sambia", "Simbabwe"
];

const AddTeamPlayerModal = ({ visible, onCancel, team }) => {
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState('');
    const [identificationUrl, setIdentificationUrl] = useState('');
    const sid = generateRandomNumber();

    const handleCreate = async () => {
        try {
            const values = await form.validateFields();
            if (!team || !team.id) {
                throw new Error('Invalid team data');
            }
            const serializedData = {
                name: values.name,
                number: values.number,
                sid: sid,
                birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
                nationality: values.nationality,
                position: values.position,
                eligibility: values.eligibility,
                registerDate: values.registerDate ? values.registerDate.format('YYYY-MM-DD') : null,
                matchesPlayed: values.matchesPlayed ?? 0, // default to 0 if undefined
                goals: values.goals ?? 0, // default to 0 if undefined
                team: { id: team.id }, // Nested team object as expected
                image: imageUrl || '', // Set the image URL
                identification: identificationUrl || '', // Set the identification URL
                status: values.status // Set the status as true or false
            };

            const apiService = new ApiService();
            console.log(serializedData);
            await apiService.post('players', serializedData);

            alert('Player data submitted!');
            form.resetFields();
            setImageUrl(''); // Reset image URL
            setIdentificationUrl(''); // Reset identification URL
            onCancel(); // Close the modal after successful submission
        } catch (errorInfo) {
            console.error('Failed:', errorInfo);
            if (errorInfo.message) {
                alert(errorInfo.message);
            }
        }
    };

    const handleImageUploadSuccess = (url) => {
        setImageUrl(url);
    };

    const handleIdentificationUploadSuccess = (url) => {
        setIdentificationUrl(url);
    };

    return (
        <Modal
            title="Add New Player"
            width={600}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleCreate}>
                    Submit
                </Button>,
            ]}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
            <Form form={form} layout="vertical" name="addPlayerForm">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please input the player name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Number"
                    name="number"
                    rules={[{ required: true, message: 'Please input the player number!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Birthday"
                    name="birthday"
                    rules={[{ required: true, message: 'Please input the player birthday!' }]}
                >
                    <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[{ required: true, message: 'Please select the player nationality!' }]}
                >
                    <Select placeholder="Select nationality">
                        {countries.map(country => (
                            <Option key={country} value={country}>
                                {country}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Position"
                    name="position"
                    rules={[{ required: true, message: 'Please select the player position!' }]}
                >
                    <Select placeholder="Select position">
                        <Option value="Feldspieler">Feldspieler</Option>
                        <Option value="Tormann">Tormann</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Eligibility"
                    name="eligibility"
                    rules={[{ required: true, message: 'Please select the player eligibility status!' }]}
                >
                    <Select placeholder="Select eligibility">
                        <Option value="Spielberechtigt">Spielberechtigt</Option>
                        <Option value="Gesperrt">Gesperrt</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Register Date"
                    name="registerDate"
                    rules={[{ required: true, message: 'Please input the register date!' }]}
                >
                    <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
                <Form.Item
                    label="Matches Played"
                    name="matchesPlayed"
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Goals"
                    name="goals"
                >
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                    label="Spieler Bild"
                    name="image"
                >
                    {!imageUrl && (
                        <FirebaseImageUpload path={`players/`} filename={sid} onUploadSuccess={handleImageUploadSuccess} />
                    )}
                    {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ height: '150px' }} />}
                </Form.Item>
                <Form.Item
                    label="Ausweis"
                    name="identification"
                >
                    {!identificationUrl && (
                        <FirebaseImageUpload path={`identifications/`} filename={sid} onUploadSuccess={handleIdentificationUploadSuccess} />
                    )}
                    {identificationUrl && <img src={identificationUrl} alt="Uploaded Identification" style={{ height: '150px' }} />}
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: 'Please select the player status!' }]}
                >
                    <Select placeholder="Select status">
                        <Option value={true}>Bestätigt</Option>
                        <Option value={false}>Nicht Bestätigt</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default AddTeamPlayerModal;