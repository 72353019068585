import { Award } from 'iconsax-react';

import IntlMessages from '../../layout/components/lang/IntlMessages';

const pages = [
  {
    header: <IntlMessages id="sidebar-pages" />,
  },

  {
    id: 'blank-page',
    title: <IntlMessages id="sidebar-pages-blank-page" />,
    icon: <i className="ph-fill ph-note-blank" />,
    navLink: '/pages/blank-page',
  },

  {
    id: 'errors',
    title: <IntlMessages id="sidebar-pages-error" />,
    icon: <i className="ph-fill ph-file-x" />,
    children: [
      {
        id: 'error-404',
        title: '404',
        navLink: '/pages/error-404',
      },
    ],
  },
];

export default pages;
