
import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import ApiService from '../../../../../network/ApiService'; // Import ApiService
import FirebaseImageUpload from "../../../../../network/Firebase/storage/FirebaseImageUpload";

const { Option } = Select;

const countries = [
    "Afghanistan", "Albanien", "Algerien", "Andorra", "Angola", "Antigua und Barbuda", "Argentinien",
    "Armenien", "Australien", "Österreich", "Aserbaidschan", "Bahamas", "Bahrain", "Bangladesch", "Barbados",
    "Weißrussland", "Belgien", "Belize", "Benin", "Bhutan", "Bolivien", "Bosnien und Herzegowina", "Botswana",
    "Brasilien", "Brunei", "Bulgarien", "Burkina Faso", "Burundi", "Kap Verde", "Kambodscha", "Kamerun",
    "Kanada", "Zentralafrikanische Republik", "Tschad", "Chile", "China", "Kolumbien", "Komoren", "Kongo",
    "Costa Rica", "Kroatien", "Kuba", "Zypern", "Tschechische Republik", "Dänemark", "Dschibuti", "Dominica",
    "Dominikanische Republik", "Ecuador", "Ägypten", "El Salvador", "Äquatorialguinea", "Eritrea", "Estland",
    "Eswatini", "Äthiopien", "Fidschi", "Finnland", "Frankreich", "Gabun", "Gambia", "Georgien", "Deutschland",
    "Ghana", "Griechenland", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras",
    "Ungarn", "Island", "Indien", "Indonesien", "Iran", "Irak", "Irland", "Israel", "Italien", "Jamaika",
    "Japan", "Jordanien", "Kasachstan", "Kenia", "Kiribati", "Kuwait", "Kirgistan", "Laos", "Lettland",
    "Libanon", "Lesotho", "Liberia", "Libyen", "Liechtenstein", "Litauen", "Luxemburg", "Madagaskar",
    "Malawi", "Malaysia", "Maldiven", "Mali", "Malta", "Marshallinseln", "Mauretanien", "Mauritius",
    "Mexiko", "Mikronesien", "Moldau", "Monaco", "Mongolei", "Montenegro", "Marokko", "Mosambik",
    "Myanmar", "Namibia", "Nauru", "Nepal", "Niederlande", "Neuseeland", "Nicaragua", "Niger",
    "Nigeria", "Nordkorea", "Nordmazedonien", "Norwegen", "Oman", "Pakistan", "Palau", "Palästina",
    "Panama", "Papua-Neuguinea", "Paraguay", "Peru", "Philippinen", "Polen", "Portugal", "Katar",
    "Rumänien", "Russland", "Ruanda", "St. Kitts und Nevis", "St. Lucia", "St. Vincent und die Grenadinen",
    "Samoa", "San Marino", "Sao Tome und Principe", "Saudi-Arabien", "Senegal", "Serbien", "Seychellen",
    "Sierra Leone", "Singapur", "Slowakei", "Slowenien", "Salomonen", "Somalia", "Südafrika",
    "Südkorea", "Südsudan", "Spanien", "Sri Lanka", "Sudan", "Surinam", "Schweden", "Schweiz",
    "Syrien", "Taiwan", "Tadschikistan", "Tansania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad und Tobago",
    "Tunesien", "Türkei", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "Vereinigte Arabische Emirate",
    "Vereinigtes Königreich", "Vereinigte Staaten", "Uruguay", "Usbekistan", "Vanuatu", "Vatikanstadt", "Venezuela",
    "Vietnam", "Jemen", "Sambia", "Simbabwe"
];

// Utility function to generate a random 8-digit password
const generateRandomPassword = () => {
    return Math.random().toString(36).slice(-8);
};

const AddRefereeModal = ({ visible, onCancel }) => {
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState('');
    const [identificationUrl, setIdentificationUrl] = useState('');
    const apiService = new ApiService(); // Instantiate ApiService

    const handleUploadSuccess = (url, type) => {
        if (type === 'image') {
            setImageUrl(url);
        } else if (type === 'identification') {
            setIdentificationUrl(url);
        }
    };

    const handleCreate = async () => {
        try {
            const values = await form.validateFields();
            const password = generateRandomPassword();
            const payload = {
                ...values,
                password,
                image: imageUrl,
                identification: identificationUrl
            }; // Include both image and identification URLs

            console.log('Sending data:', payload);
            await apiService.post('referees', payload); // Use ApiService
            alert('Referee created successfully!');
            onCancel(); // Close the modal after successful creation
        } catch (error) {
            console.log('Failed:', error);
        }
    };

    return (
        <Modal
            title="Referee hinzufügen"
            width={600}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" onClick={handleCreate}>
                    Speichern
                </Button>,
            ]}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
            <Form form={form} layout="vertical" name="addRefereeForm">
                {/* Referee Section */}
                <h5>Referee Details</h5>
                <Form.Item
                    label="Vorname"
                    name="first"
                    rules={[{ required: true, message: 'Please input the first name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Nachname"
                    name="last"
                    rules={[{ required: true, message: 'Please input the last name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email Adresse"
                    name="email"
                    rules={[{ required: true, message: 'Please input the email address!' }]}
                >
                    <Input type="email" />
                </Form.Item>
                <Form.Item
                    label="Image"
                >
                    <FirebaseImageUpload
                        onUploadSuccess={(url) => handleUploadSuccess(url, 'image')}
                        path={`referees/${Date.now()}`}
                        filename="referee-image"
                        existingUrl={imageUrl}
                        buttonText="Upload Image"
                    />
                </Form.Item>
                <Form.Item
                    label="Identification Document"
                >
                    <FirebaseImageUpload
                        onUploadSuccess={(url) => handleUploadSuccess(url, 'identification')}
                        path={`referees/identifications/${Date.now()}`}
                        filename="referee-identification"
                        existingUrl={identificationUrl}
                        buttonText="Upload Identification"
                    />
                </Form.Item>
                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[{ required: true, message: 'Please select the nationality!' }]}
                >
                    <Select placeholder="Select nationality">
                        {countries.map(country => (
                            <Option key={country} value={country}>{country}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddRefereeModal;
