import React, { useState } from "react";
import { Row, Col, Card } from "antd";

import AddUserModal from "./subviews/user/AddUserModal";
import AddTeamPlayerModal from "./subviews/teams/AddTeamPlayerModal";
import AddRefereeModal from "./subviews/referees/AddRefereeModal";
import AddLeagueModal from "./subviews/leagues/AddLeagueModal";
import ManualRegistrationModal from "./subviews/registrations/ManualRegistrationModal";
import AssignRefereeModal from "./subviews/referees/AssignRefereeModal";
import AddStadiumModal from "./subviews/stadium/AddStadiumModal";

export default function AdminPanel() {
    const [isAddUserModalVisible, setAddUserModalVisible] = useState(false);
    const [isAddRefereeModalVisible, setAddRefereeModalVisible] = useState(false);
    const [isAddLeagueModalVisible, setAddLeagueModalVisible] = useState(false);
    const [isAddRegistrationModalVisible, setAddRegistrationModalVisible] = useState(false);
    const [isAddAssignRefereeModalVisible, setAddAssignRefereeModalVisible] = useState(false);
    const [isAddStadiumModalVisible, setAddStadiumModalVisible] = useState(false);

    // USER Registration
    const showAddUserModal = () => {
        setAddUserModalVisible(true);
    };

    const handleAddUserModalCancel = () => {
        setAddUserModalVisible(false);
    };

    const handleAddUserModalCreate = () => {
        // Handle user creation logic here
        setAddUserModalVisible(false);
    };

    // Registration
    const showAddRegistrationModal = () => {
        setAddRegistrationModalVisible(true);
    };

    const handleAddRegistrationModalCancel = () => {
        setAddRegistrationModalVisible(false);
    };

    const handleAddRegistrationModalCreate = () => {
        // Handle user creation logic here
        setAddRegistrationModalVisible(false);
    };

    // Referee
    const showAddRefereeModal = () => {
        setAddRefereeModalVisible(true);
    };

    const handleAddRefereeModalCancel = () => {
        setAddRefereeModalVisible(false);
    };

    const handleAddRefereeModalCreate = () => {
        // Handle user creation logic here
        setAddRefereeModalVisible(false);
    };

    // Assign Referee
    const showAddAssignRefereeModal = () => {
        setAddAssignRefereeModalVisible(true);
    };

    const handleAddAssignRefereeModalCancel = () => {
        setAddAssignRefereeModalVisible(false);
    };

    // League
    const showAddLeagueModal = () => {
        setAddLeagueModalVisible(true);
    };

    const handleAddLeagueModalCancel = () => {
        setAddLeagueModalVisible(false);
    };

    const handleAddLeagueModalCreate = () => {
        // Handle user creation logic here
        setAddLeagueModalVisible(false);
    };

    // Stadium
    const showAddStadiumModal = () => {
        setAddStadiumModalVisible(true);
    };

    const handleAddStadiumModalCancel = () => {
        setAddStadiumModalVisible(false);
    };

    return (
        <div>
            <Row gutter={[32, 32]} className="hp-mb-32">
                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{ minHeight: '230px'}}>
                        <h4>Admin Benutzer</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/user">Benutzer Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="#" onClick={showAddUserModal}>Neuen Benutzer hinzufügen</a>
                        </p>
                    </Card>
                </Col>

                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{minHeight: '230px'}}>
                        <h4>Mannschaften</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/team">Team Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="/admin/team/users">Team Login Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="/admin/players">Spieler Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="/admin/team/pending">Spieler Berechtigungen</a>
                        </p>

                        <p className="hp-mb-4">
                            <a href="/admin/transfers">Transfer Übersicht</a>
                        </p>

                        <p className="hp-mb-4">
                            <a href="/admin/transfer_settings">Transfer Einstellungen</a>
                        </p>

                    </Card>
                </Col>

                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{minHeight: '230px'}}>
                        <h4>Schiedsrichter</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/referee">Schiedsrichter Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="#" onClick={showAddRefereeModal}>Neuen Schiedsrichter hinzufügen</a>
                        </p>
                    </Card>
                </Col>

                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{minHeight: '230px'}}>
                        <h4>Strafsenat</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/strafsenat">Strafsenat Übersicht</a>
                        </p>
                    </Card>
                </Col>


                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{ minHeight: '230px'}}>
                        <h4>Ligen</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/referee">Schiedsrichter Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="#" onClick={showAddLeagueModal}>Neue Liga hinzufügen</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="/admin/league">Ligen und Spielplan</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="/admin/stadiums">Stadion Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="#" onClick={showAddStadiumModal}>Neues Stadion hinzufügen</a>
                        </p>
                    </Card>
                </Col>

                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{ minHeight: '230px'}}>
                        <h4>Neue Anmeldungen</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/registrations">Registrierungs Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="#" onClick={showAddRegistrationModal}>Manuelle Registrierung starten</a>
                        </p>
                    </Card>
                </Col>

                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{ minHeight: '230px'}}>
                        <h4>Nachrichten</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/chat">Admin Posteingang</a>
                        </p>
                    </Card>
                </Col>

                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{minHeight: '230px'}}>
                        <h4>Finanzen</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/rechnungen">Rechnungen Übersicht</a>
                        </p>
                        <p className="hp-mb-4">
                            <a href="/admin/rechnung_erstellung">Neue Rechnung erstellen</a>
                        </p>
                    </Card>
                </Col>

                <Col xl={8} md={12} xs={24}>
                    <Card className="hp-knowledge-basic-card" style={{minHeight: '230px'}}>
                        <h4>Homepage</h4>
                        <p className="hp-mb-4">
                            <a href="/admin/blog">Blog Übersicht</a>
                        </p>

                        <p className="hp-mb-4">
                            <a href="/admin/blog">Startseite</a>
                        </p>
                    </Card>
                </Col>
            </Row>

            <AddUserModal
                visible={isAddUserModalVisible}
                onCancel={handleAddUserModalCancel}
                onCreate={handleAddUserModalCreate}
            />
            <AddRefereeModal
                visible={isAddRefereeModalVisible}
                onCancel={handleAddRefereeModalCancel}
                onCreate={handleAddRefereeModalCreate}
            />
            <AddLeagueModal
                visible={isAddLeagueModalVisible}
                onCancel={handleAddLeagueModalCancel}
            />

            <ManualRegistrationModal
                visible={isAddRegistrationModalVisible}
                onCancel={handleAddRegistrationModalCancel}
            />

            <AssignRefereeModal
                visible={isAddAssignRefereeModalVisible}
                onCancel={handleAddAssignRefereeModalCancel}
            />
            <AddStadiumModal
                visible={isAddStadiumModalVisible}
                onCancel={handleAddStadiumModalCancel}
            />
        </div>
    );
}
