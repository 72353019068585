import React from 'react';
import { Modal, Form, Input, InputNumber, Select, Button } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import ApiService from '../../../../../network/ApiService';

const { Option } = Select;

// Define Bundesland statically
const Bundesland = {
    wien: 'Wien',
    niederoesterreich: 'Niederösterreich',
    oberoesterreich: 'Oberösterreich',
    steiermark: 'Steiermark',
    kaernten: 'Kärnten',
    salzburg: 'Salzburg',
    tirol: 'Tirol',
    vorarlberg: 'Vorarlberg',
    burgenland: 'Burgenland',
    ausgetreten: 'Ausgetreten'
};

const AddLeagueModal = ({ visible, onCancel }) => {
    const [form] = Form.useForm();
    const apiService = new ApiService();

    const handleCreate = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                _id: 'B236BAAD-404C-4451-9C30-122CBD9EB0DA', // This would typically be generated by the backend
                ...values
            };
            await apiService.post('leagues', payload);
            alert('League created successfully!');
            form.resetFields(); // Reset form fields after successful submission
            onCancel(); // Dismiss the modal after submission
        } catch (error) {
            alert('Creation failed: ' + error.message);
        }
    };

    return (
        <Modal
            title="Neue Liga Erstellen"
            width={416}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleCreate}>
                    Speichern
                </Button>,
            ]}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
            <Form form={form} layout="vertical" name="addLeagueForm">
                <Form.Item
                    label="Name der Liga"
                    name="name"
                    rules={[{ required: true, message: 'Bitte geben Sie den Namen der Liga ein!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Code der Liga"
                    name="code"
                    rules={[{ required: true, message: 'Bitte geben Sie den Code der Liga ein!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Bundesland"
                    name="state"
                    rules={[{ required: true, message: 'Bitte wählen Sie ein Bundesland!' }]}
                >
                    <Select placeholder="Select a state">
                        {Object.entries(Bundesland).map(([key, name]) => (
                            <Option key={key} value={key}>{name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Hourly"
                    name="hourly"
                    rules={[{ required: true, message: 'Bitte geben Sie den Stundensatz ein!' }]}
                >
                    <InputNumber min={0} step={0.01} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddLeagueModal;
