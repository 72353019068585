import React from 'react';
import { Modal, Form, Input, Button, Switch, DatePicker, Select } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import ApiService from "../../../../../network/ApiService";

const AddStadiumModal = ({ visible, onCancel }) => {
    const [form] = Form.useForm();
    const apiService = new ApiService();

    const handleCreate = async () => {
        try {
            const values = await form.validateFields();
            const formattedValues = {
                ...values,
                partnerSince: values.partnerSince ? values.partnerSince.format('YYYY/MM') : undefined,
            };
            await apiService.post('stadiums', formattedValues);
            alert('Stadium created successfully!');
            form.resetFields();
            onCancel();
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    return (
        <Modal
            title="Neues Stadium Erstellen"
            width={416}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" onClick={handleCreate}>
                    Speichern
                </Button>,
            ]}
            closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
        >
            <Form form={form} layout="vertical" name="addStadiumForm">
                <Form.Item
                    label="Code"
                    name="code"
                    rules={[{ required: true, message: 'Bitte geben Sie den Code ein!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Bitte geben Sie den Namen ein!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="address"
                    rules={[{ required: true, message: 'Bitte geben Sie die Adresse ein!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Type"
                    name="type"
                    rules={[{ required: true, message: 'Bitte geben Sie den Typ ein!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Schuhwerk"
                    name="schuhwerk"
                    rules={[{ required: true, message: 'Bitte geben Sie das Schuhwerk ein!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Parking Available"
                    name="parking"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Flutlicht Available"
                    name="flutlicht"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label="Partner Since"
                    name="partnerSince"
                >
                    <DatePicker format="YYYY/MM" picker="month" />
                </Form.Item>
                <Form.Item
                    label="Home Team"
                    name="homeTeam"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Bundesland"
                    name="bundesland"
                    rules={[{ required: true, message: 'Bitte wählen Sie ein Bundesland aus!' }]}
                >
                    <Select>
                        <Select.Option value="wien">Wien</Select.Option>
                        <Select.Option value="niederoesterreich">Niederösterreich</Select.Option>
                        <Select.Option value="oberoesterreich">Oberösterreich</Select.Option>
                        <Select.Option value="steiermark">Steiermark</Select.Option>
                        <Select.Option value="kaernten">Kärnten</Select.Option>
                        <Select.Option value="salzburg">Salzburg</Select.Option>
                        <Select.Option value="tirol">Tirol</Select.Option>
                        <Select.Option value="vorarlberg">Vorarlberg</Select.Option>
                        <Select.Option value="burgenland">Burgenland</Select.Option>
                        <Select.Option value="ausgetreten">Ausgetreten</Select.Option>
                        <Select.Option value="auszuwerten">Auszuwerten</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddStadiumModal;
