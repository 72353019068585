import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Form, Input, Button, Checkbox, message, Modal } from "antd";
import LeftContent from "../leftContent";
import Footer from "../footer";
import { useAuth } from "../../../../network/authContext";
import AuthService from "../../../../network/AuthService";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const history = useHistory();
  const { login } = useAuth();
  const authService = new AuthService();

  /**
   * Handles login form submission.
   * @param {Object} values - The form values.
   */
  const handleLogin = async (values) => {
    setLoading(true);
    const { username, password } = values;

    const result = await login(username, password);
    setLoading(false);

    if (result.success) {
      const userType = result.user.type;
      message.success('Login successful');

      // Show cookie consent modal after successful login
      setShowCookieConsent(true);

      // Store user data temporarily for setting cookies and redirection after consent
      sessionStorage.setItem("userType", userType);
      sessionStorage.setItem("userId", result.user.id);
      sessionStorage.setItem("authToken", result.token);
    } else {
      message.error(result.message);
    }
  };

  /**
   * Handles the user's consent for cookies.
   */
  const handleCookieConsent = () => {
    const userType = sessionStorage.getItem("userType");
    const userId = sessionStorage.getItem("userId");
    const token = sessionStorage.getItem("authToken");

    // Clear temporary storage
    sessionStorage.removeItem("userType");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("authToken");

    // Set cookies after user consents
    authService.setAuthCookies(userId, token);

    setShowCookieConsent(false);

    // Redirect based on user type
    switch (userType) {
      case 'admin':
        history.push('/admin/');
        break;
      case 'referee':
        history.push('/referee-dashboard');
        break;
      case 'team':
        history.push('/team-dashboard');
        break;
      default:
        history.push('/');
    }
  };

  return (
      <>
        <Row gutter={[32, 0]} className="hp-authentication-page">
          <LeftContent />

          <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
            <Row className="hp-h-100" align="middle" justify="center">
              <Col
                  xxl={11}
                  xl={15}
                  lg={20}
                  md={20}
                  sm={24}
                  className="hp-px-sm-8 hp-pt-24 hp-pb-48"
              >
                <h1 className="hp-mb-sm-0">Login</h1>
                <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
                  Willkommen zurück, bitte melden Sie sich bei Ihrem Konto an.
                </p>

                <Form
                    layout="vertical"
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={handleLogin}
                    className="hp-mt-sm-16 hp-mt-32"
                >
                  <Form.Item
                      label="Username :"
                      name="username"
                      rules={[{ required: true, message: 'Bitte geben Sie Ihre Email ein' }]}
                      className="hp-mb-16"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                      label="Password :"
                      name="password"
                      rules={[{ required: true, message: 'Bitte geben Sie Ihre Passwort ein' }]}
                      className="hp-mb-8"
                  >
                    <Input.Password />
                  </Form.Item>

                  <Row align="middle" justify="space-between">
                    <Form.Item name="remember" valuePropName="checked" className="hp-mb-0">
                      <Checkbox>Eingeloggt bleiben</Checkbox>
                    </Form.Item>

                    <Link
                        className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                        to="/pages/authentication/recover-password"
                    >
                      Passwort Vergessen?
                    </Link>
                  </Row>

                  <Form.Item className="hp-mt-16 hp-mb-8">
                    <Button block type="primary" htmlType="submit" loading={loading}>
                      Eingloggen
                    </Button>
                  </Form.Item>
                </Form>
                <Footer />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Cookie Consent Modal */}
        <Modal
            title="Cookie Zustimmung"
            visible={showCookieConsent}
            onOk={handleCookieConsent}
            onCancel={() => setShowCookieConsent(false)}
            okText="Akzeptieren"
            cancelText="Ablehnen"
        >
          <p>
            Wir verwenden Cookies, um Ihre Erfahrung zu verbessern. Durch die weitere Nutzung der Seite stimmen Sie unserer Verwendung von Cookies zu. Akzeptieren Sie?
          </p>
        </Modal>
      </>
  );
}
